export const DEFAULT_LANG = 'ro';
export const FALLBACK_LANG = 'en';
export const SUPPORTED_LANGUAGES = [DEFAULT_LANG, FALLBACK_LANG];

export const TABLE_VIEW_MODE = 'table';
export const GRID_VIEW_MODE = 'grid';
export const LIST_VIEW_MODE = 'list';
export const VIEW_MODES = [TABLE_VIEW_MODE, GRID_VIEW_MODE, LIST_VIEW_MODE];

export const PROMOTION_PRGF = 'PRGF';
export const PROMOTION_BNDL = 'BNDL';
export const PROMOTION_N1 = 'N+1';
export const PROMOTION_DSC = 'DSC';
export const PROMOTION_TYPES = [PROMOTION_DSC, PROMOTION_PRGF, PROMOTION_BNDL, PROMOTION_N1];
export const NETWORK_ONE_DISTRIBUTION = 'Network One Distribution';

export const CURRENCY_HUF = 'huf';
export const CURRENCY_BGN = 'bgn';
export const CURRENCY_EUR = 'eur';
export const CURRENCY_USD = 'usd';
export const CURRENCY_RON = 'ron';

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

export const COUNTRIES = {
  21: {
    name: 'Bulgaria',
    currency: CURRENCY_BGN,
    symbol: 'BGN',
    zipCodeRegex: /^[0-9]{4}$/,
    zipCodePlaceholder: 'xxxx',
    zipCodeLength: 4,
    zipCodeRequired: false,
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  101: {
    name: 'Hungary',
    currency: CURRENCY_HUF,
    zipCodeRegex: /^[0-9]{4}$/,
    zipCodePlaceholder: 'xxxx',
    zipCodeLength: 4,
    zipCodeRequired: true,
    symbol: 'Ft',
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  182: {
    name: 'Poland',
    currency: CURRENCY_EUR,
    symbol: '€',
    zipCodeRegex: /^[0-9]{2}-[0-9]{3}$/,
    zipCodePlaceholder: 'xx-xxx',
    zipCodeLength: 6,
    zipCodeRequired: true,
    phoneNumberRegex: /^(\+|)?([0-9]{10,15})$/
  },
  192: {
    name: 'Romania',
    currency: CURRENCY_RON,
    symbol: 'Lei',
    zipCodeRegex: /^[0-9]{6}$/,
    zipCodePlaceholder: 'xxxxxx',
    zipCodeLength: 6,
    zipCodeRequired: true,
    phoneNumberRegex: /^(\+|)?([0-9]{9,13})$/
  }
};

// TODO: Remove this after Black Friday
export const blackFridayProducts = [
  'VM-EPL-VB-PLUS-1',
  'VB-400-QR-KFTILT2',
  'HALO-V2.00',
  'HALO-SMADAPT',
  '2.0C-H5A-D1-IR',
  'VMA-AIA1-CG1-EU',
  'VMA-AS3-16P12-EU',
  'H4-BO-JBOX1',
  'H4AMH-AD-IRIL1',
  'POE-INJ2-95W-NA',
  'EVA8.A2',
  'DA.SO4',
  'LADY.L',
  'EVA7.A2',
  'FTC.S',
  'LADY.A',
  'LADY.P5',
  'RI.M4F-4BUC',
  'VE.KM1HN',
  'VE.P650',
  '704917',
  '808004',
  '786018',
  '704901',
  '704903',
  '782426',
  '704902',
  'CWSO-RR-S1',
  '804382.D0',
  'NCO100',
  'CS-BC1C-A02C4WPBDL',
  'EB3 2K',
  'SOLAR PANEL-D',
  'DS-KD8003-IME1/EU',
  'DS-KAB10-D',
  'DS-2CE19D0T-VFIT3F',
  'DS-2CE79D0T-VFIT3F',
  'DS-2CE72DF3T-FS36',
  'TS-2495-180',
  '3-4U/RACK/RAIL',
  'DS2CD1H23G0IZ2812C',
  'DS-2AE5232TI-A(E)',
  'DS-2CE16D8T-ITE2.8',
  'DS-2CE79U7T-AIT3ZF',
  'DS-2CD1753G0-IZ-C',
  'HK-1.25G-20-1550',
  'DS-7116HGHI-K1/C/S',
  'DS-2CE79H0T-IT3ZE',
  'DS-7108HQHI-K1CS',
  'DS-2CE16D8T-IT5F36',
  'DS-2CE70DF8T-MFSLN',
  'DS-K1T501SF',
  'DS-7104HQHI-K1/C/S',
  'IDS-7316HQHI-M4/S',
  'DS-7104NI-K1/W/M1T',
  'DS-PDSK-P',
  'DS-2CD2086G2-I28C',
  'DS-2CE56D0T-VPIR3E',
  'DS-U02P',
  'DS-U04P',
  'DS-UL2',
  'DS-UL8',
  'DS-2CD2935FWD-I',
  'DS-2CD2955FWD-IS',
  'DS-2CV2027G0-LDW2',
  'DS-2DE1C200IW-D3W',
  'DS-2PT3306IZ-DE3',
  'DS-UA12',
  'DS-UC2',
  'DS-UC8',
  'IDS-UL2P/BK',
  'IDS-UL2P/WH',
  'IDS-UL4P/BK',
  'IDS-UL4P/WH',
  'DS-PDTT15AM-LM',
  'DS-2CD2647G2-LZSC',
  'DS-2CD2955FWD-I',
  'DS-2CV2021G2-IDW4E',
  'DS-2CV2026G0-IDW2D',
  'DS-2CV2121G2-IDW2',
  'DS-2CV2121G2-IDW2E',
  'DS-2CV2126G0-IDW2',
  'DS-2CV2146G0-IDW2',
  'DS-2PT3122IZ-DE3',
  'DS-2CD2027G2-L28C',
  'DS-2CD2326G2-I28C',
  'DS-2CD2326G2-IU28C',
  'DS-2CD2327G2-L28',
  'DS-2CD2647G2T-LZSC',
  'DS-2CD2747G2-LZSC',
  'DS-2CD2747G2T-LZSC',
  'DS-E04HQHI-B',
  'DS-2CD2143G2-LSU28',
  'DS-7616NXI-I216PSC',
  'DS-2AE7232TI-A',
  'DS-2CD2343G2-L28',
  'DS-2CD2443G0-IW28W',
  'DS-2CD1127G0(2.8MM)(C)',
  'DS-7608NXI-I2/SC',
  'DS-2CD2423G0-IW28W',
  'DS-2CE72DF0T-LFS(2.8MM)',
  'DS-3WF01C-2N',
  'DS-2CE18D0T-LFS(2.8MM)',
  'DS-DM4255W',
  'IDS-7208HUHI-M2/S(C)/4A+8/4ALM',
  'DS-2CD2043G2-I28',
  'DS-2CE76K0T-LPFS(2.8MM)',
  'DS-2CE78D0T-LFS(2.8MM)',
  'DS-2CE78K0T-LFS(2.8MM)',
  'DS-3E0528HP-E',
  'DS-3E1526P-SI',
  'DS-2CE79U1T-IT3ZF',
  'DS-DM1940W',
  'HWI-T240-28(C)',
  'HWP-N2204IH-DE3(F)',
  'HWD-7104MH-G3',
  'HWT-B320-VFC',
  'HWT-T350-Z27135(C)',
  'HWD-6116MH-G4',
  'HWI-D121H-28C',
  'HWI-D620H-Z2812(C)',
  'HWI-D640H-ZC',
  'HWI-T641H-Z2812(C)',
  'HWT-B323-Z',
  'HWI-D129H-28(D)',
  'HWI-T221H-28(C)',
  'HWI-T229H-28(C)',
  'HWN-2104MH(D)',
  'HWN-4104MH(D)',
  'HWT-B150-M-28',
  'HWT-T120-M(2.8MM)',
  'HWT-T120-P-28',
  'HWT-T150-P-28',
  'HWT-T250-M-28',
  'HWN-2108MH-W(C)',
  'HWT-T150-M28',
  'HWN-4104MH-4P(D)',
  'RESIG0000021296',
  'CWST-RW-S5',
  'MI-D240CMOE',
  'ND-EB15-1',
  'ND-EB30',
  'ND-EDR911',
  'ND-EDR911-C',
  'B350',
  'B430',
  'B440',
  'P320',
  'P434',
  'P344',
  'NVS4',
  'NVS8(NHD)',
  'P320X',
  'P340',
  'W520',
  'W730',
  'W760',
  'W330',
  'B420',
  'G330',
  'P730',
  'D340P',
  'D340W',
  'D20',
  'VELA-W',
  'VELA 2.0B',
  'VELA 2.0W',
  'VELA 20 CH-W',
  'VELA RX 12-24',
  'TL 2410 IP',
  'TL 2415 IP',
  'XMA210',
  'KF240',
  'OSX200',
  'XMB210',
  'XTO-IP210',
  'OMV210',
  'GLASSPROTECT BL',
  'MOTIONPROTECTCURTW',
  'MOTIONPROTECTOUT W',
  '30-3500-0001-50-01',
  '30-3500-0001-75-01',
  'YLFB/200/EB1',
  'YLFM/200/EG1',
  'DHI-KTP01L(F)',
  'DHI-KTP01L(S)',
  'DHI-KTX01(F)',
  'DHI-KTX01(S)',
  'DH-SD1A404XB-GNR-W',
  'IPC-HFW3441DGP-AS-4G-NL668EAU-B-0280B',
  'SD2A400HB-GN-AGQ-PV-SP-EAU',
  'TR40',
  '784850',
  '18007',
  '704915',
  '784840,1',
  '805576',
  '805588',
  '808606',
  'FX808340',
  'HLSPS50',
  '704950',
  '804900',
  '802177',
  '802375',
  '807214RR',
  'CWST-RR-S5',
  'CS-T4C',
  'CS-T10C',
  'CS-T51C',
  'CS-B1',
  'CS-T30-10B-EU',
  'CS-T36-R100-WM',
  'HP4',
  'CS-H3-R100-1H3WKFL',
  'CS-H6C-R101-1G2WF',
  'CS-H8-R100-1H3WKFL',
  'CS-TY1-B0-1G2WF',
  'CS-TY1-C0-8B4WF',
  '100W SOLAR PANEL',
  '200W SOLAR PANEL',
  'R200C-2',
  'R200S-2',
  'VTL3030ES15',
  'MS-S0208-EL',
  'MS-S0216-GL',
  'MS-S0224-GL',
  'MS-C2964-PD',
  'MS-C2964-RFLPC',
  'MS-C2964-RFPE',
  'MS-C2964-UPD',
  'MS-C2966-X12RLPC',
  'MS-C2966-X12ROPC',
  'MS-C2972-RFPE',
  'MS-C2973-PD',
  'MS-C2975-RFPD',
  'MS-C5363-PA',
  'MS-C5364-FLPC',
  'MS-C5364-FPE',
  'MS-C5364-UPD',
  'MS-C5365-PE',
  'MS-C5366-FGPC',
  'MS-C5366-X12LVPC',
  'MS-C5366-X12PA',
  'MS-C5371-X12PC',
  'MS-C5372-FPE',
  'MS-C5373-PD',
  'MS-C5373-PD(BLACK)',
  'MS-C5375-FPD',
  'MS-C5375-PD',
  'MS-C5376-PE',
  'MS-C8163-PA',
  'MS-C8164-PD',
  'MS-C8164-UPD',
  'MS-C8165-PE',
  'MS-C8172-FPE',
  'MS-C8173-PD',
  'MS-C8175-FPD',
  'MS-C8183-PD',
  'MS-C8274-PA',
  'MS-C9674-PA',
  'TS2866-X4TVPC',
  'TS2866-X4TVPE',
  'TS2961-X12TPC',
  'MS-N7032-G',
  'MS-N7048-UPH',
  'MS-N8064-G',
  'A41',
  'A71',
  'CW-G3',
  'FLX-A-DAM-X5',
  'HX-80N(C)',
  'QXI-ST',
  'SL-200QDP(C)',
  'SX-360Z',
  'WXI-AM',
  'UTP-5E-0.5-G',
  'BD-EB08',
  'ND-EB02/10BUC',
  'ND-EB02A',
  'ND-EB24',
  'ND-EB30',
  'DORCAS-SF99-1-NF305-TOP/YL-X',
  'K10-EM',
  '2X-FR-S99',
  'ND-FS911',
  'ND-EDR911-C',
  'ND-PPM03',
  'ND-GD962',
  'ND-WD100',
  'BD-5-4K',
  'BD-10-4K',
  'BD-15-4K',
  'BD-20-4K',
  'BD-30-4K',
  'DC-001-10BUC',
  'DC-002-10BUC',
  'DC-04M-10BUC',
  'DC-05F-10BUC',
  'DC-05M-10BUC',
  'DC-06F-10BUC',
  'DC1-3',
  'DC1-4',
  'HM-926',
  'HM-938PV',
  'HM-950',
  'RJ45-C1-10BUC',
  'CABSHELF/350',
  'HM-1216C',
  'HM-1602',
  'HM-3018',
  'HM-A120 12V 1A',
  'HM-A122 12V 2A',
  'HM-A128 12V 2A',
  'HM-A133 12V 3A',
  'HM-A134 12V 4A',
  'HM-A135 12V 5A',
  'HM-A136 12V 6A',
  'HM-A137 12V 7A',
  'HM-A138 12V 8A',
  'HM-A1310 12V 10A',
  'LN-CTB03B',
  'POE-001',
  'POE-05',
  'POE-69P30F',
  'POE-4805',
  'POE-R01',
  'POE05-2',
  'HUB2',
  'IP180-W',
  'REM101',
];
